<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return">
					<el-button type="text" size="small" @click="back()">返回</el-button>
				</p>
				<div class="h-b">
					<el-button type="primary" size="small" @click="addMemberInfo"
						v-right-code="'Memberinfo:Createshipprovider'">新增</el-button>
				</div>
				<div class="h-b">
					<el-button type="text" size="small" @click="saveMemberInfo"
						v-right-code="'Memberinfo:Createshipprovider'">保存</el-button>
				</div>
			</div>
		</div>

		<el-tabs class="done" type="border-card" v-model="activeName" @tab-click="tab1Click">
			<el-tab-pane label="基本信息" name="tabMemberInfo">
				<div class="form-list">
					<el-form ref="_memberInfoForm" :model="dataSource" :rules="memberInfoCheckRule">
						<el-form-item>
							<div :hidden="!isDisabled">
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>承运商编号：</el-col>
								<el-col :span="7">
									<el-form-item prop="MemberCode">
										<el-input v-model="dataSource.MemberCode" :maxlength="30" :minlength="4"
											:disabled="true"></el-input>
									</el-form-item>
								</el-col>
							</div>
						</el-form-item>
						<el-form-item>
							<el-col :span="3" class="form-title"><span style="color:red;">*</span>承运商名称：</el-col>
							<el-col :span="7">
								<el-form-item prop="MemberName">
									<el-input v-model="dataSource.MemberName" :maxlength="30" :minlength="4"
										placeholder="承运商名称"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="3" class="form-title">承运商简称：</el-col>
							<el-col :span="7">
								<el-form-item prop="ShortName">
									<el-input v-model="dataSource.ShortName" :maxlength="30" :minlength="4"
										placeholder="承运商简称"></el-input>
								</el-form-item>
							</el-col>
						</el-form-item>
						<el-form-item>
							<el-col :span="3" class="form-title"><span style="color:red;">*</span>承运商类别：</el-col>
							<el-col :span="7">
								<el-form-item prop="MemberType">
									<DictionarySelect v-model="dataSource.MemberType" dicTypeCode="MemberTypeCode"
										datType="Int"></DictionarySelect>
								</el-form-item>
							</el-col>
							<el-col :span="3" class="form-title"><span style="color:red;">*</span>业务范围：</el-col>
							<el-col :span="7">
								<el-form-item prop="BusinessScope">
									<DictionarySelect datType="Int" v-model="dataSource.BusinessScope"
										dicTypeCode="MemberBusinessScopeCode"></DictionarySelect>
								</el-form-item>
							</el-col>
							<!-- <el-col :span="3" class="form-title" :hidden="!isExpress"><span style="color:red;">*</span>快递公司编码：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="ExpCompanyCode" :hidden="!isExpress">

                                    <el-select v-model="dataSource.ExpCompanyCode" collapse-tags placeholder="请选择" :maxlength="200">
                                        <el-option v-for="item in expressOptions"
                                                   :key="item.BusinessCode"
                                                   :label="item.BusinessCode"
                                                   :value="item.BusinessCode">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col> -->
						</el-form-item>
						<el-form-item>
							<el-col :span="3" class="form-title"><span style="color:red;">*</span>服务方式：</el-col>
							<el-col :span="7">
								<el-form-item prop="ServiceType">
									<DictionarySelect v-model="dataSource.ServiceType"
										dicTypeCode="MemberServiceTypeCode" datType="Int"></DictionarySelect>
								</el-form-item>
							</el-col>
							<el-col :span="3" class="form-title"><span style="color:red;">*</span>承运商类型：</el-col>
							<el-col :span="7">
								<el-form-item prop="BelongType">
									<DictionarySelect v-model="dataSource.BelongType" dicTypeCode="MemberBelongTypeCode"
										datType="Int"></DictionarySelect>
								</el-form-item>
							</el-col>
						</el-form-item>
						<el-form-item>
							<el-col :span="3" class="form-title"><span style="color:red;">*</span>状态：</el-col>
							<el-col :span="7">
								<el-radio-group v-model="dataSource.MemberStatus">
									<el-radio :label="1">启用</el-radio>
									<el-radio :label="2">停用</el-radio>
								</el-radio-group>
							</el-col>
							<el-col :span="3" class="form-title">所属站点：</el-col>
							<el-col :span="7">
								<el-form-item prop="SiteId">
									<LookPopup v-model="dataSource.SiteName" dataTitle="所属站点"
											dataUrl='omsapi/siteinfo/getpagelist' syncField='SiteName'
											@syncFieldEvent="syncSiteEvent">
										</LookPopup>
								</el-form-item>
							</el-col>
						</el-form-item>
						<!-- <el-form-item>
							<el-col :span="3" class="form-title">
								所属组织：
							</el-col>
							<el-col :span="7" class="form-title">
								<el-form-item>
									<LookPopup v-model="dataSource.OrganizationName" dataTitle="所属组织"
											dataUrl='omsapi/organization/pageList' syncField='OrganizationName'
											@syncFieldEvent="syncOrgEvent">
										</LookPopup>
								</el-form-item>
							</el-col>
							<el-col :span="3" class="form-title">联系电话：</el-col>
							<el-col :span="7">
								<el-form-item prop="ContactMobile">
									<el-input v-model="dataSource.ContactMobile" :maxlength="30" :minlength="4"
										placeholder="联系电话"></el-input>
								</el-form-item>
							</el-col>
						</el-form-item> -->
						<el-form-item>
							<el-col :span="3" class="form-title">
								注册地址：
							</el-col>
							<el-col :span="9">
								<Distpicker :placeholders="dataSource"></Distpicker>
							</el-col>
							<el-col :span="8">
								<el-form-item prop="MemberAddress">
									<el-input v-model="dataSource.MemberAddress" :maxlength="200"
										placeholder="详细地址"></el-input>
								</el-form-item>
							</el-col>
						</el-form-item>

						<el-form-item>
							<el-col :span="3" class="form-title">描述：</el-col>
							<el-col :span="17">
								<el-form-item prop="Memo">
									<el-input type="textarea" v-model="dataSource.Memo" :maxlength="500"></el-input>
								</el-form-item>
							</el-col>
						</el-form-item>
					</el-form>
				</div>

				<el-tabs class="done" type="border-card" v-model="activeName1">
					<el-tab-pane label="承运商联系人" name="tabproductinfo">
						<div class="h-table-list">
							<div class="h-handle-button h-handle-bg">
								<div class="h-b">
									<el-button type="primary" size="small" @click="addContactInfo">新增</el-button>
								</div>
							</div>
							<div class="h-scroll">
								<div class="table-s">
									<el-table :data="dataSource.LinkManDTOList" border highlight-current-row>
										<el-table-column width="55" label="序号">
											<template slot-scope="scope">
												<span>{{scope.$index+1}}</span>
											</template>
										</el-table-column>
										<el-table-column prop="ContactName" label="联系人">
											<template slot-scope="scope">
												<el-col :span="23">
													<el-input v-model="scope.row.ContactName" size="mini" placeholder="联系人" />
												</el-col>
											</template>
										</el-table-column>
										<el-table-column prop="ContactPhone" label="联系电话">
											<template slot-scope="scope">
												<el-col :span="23">
													<el-input v-model="scope.row.ContactPhone" size="mini" placeholder="联系电话" />
												</el-col>
											</template>
										</el-table-column>
										<el-table-column prop="Email" label="电子邮箱">
											<template slot-scope="scope">
												<el-col :span="23">
													<el-input v-model="scope.row.Email" size="mini" placeholder="电子邮箱" />
												</el-col>
											</template>
										</el-table-column>
										<el-table-column fixed="right" label="操作" width="120">
											<template slot-scope="scope">
												<el-button @click="dataSource.LinkManDTOList.splice(scope.$index, 1)" size="small" type="text">删除</el-button>
											</template>
										</el-table-column>
									</el-table>
								</div>
							</div>
						</div>
						
					</el-tab-pane>
				</el-tabs>
				<el-tabs class="done" type="border-card" v-model="activeName2">
					<el-tab-pane label="装卸货地址" name="tabproductinfo">
						<div class="h-table-list">
							<div class="h-handle-button h-handle-bg">
								<div class="h-b">
									<el-button type="primary" size="small" @click="addAddressInfo">新增</el-button>
								</div>
							</div>
							<div class="h-scroll">
								<div class="table-s">
									<el-table :data="dataSource.AddressInfoList" border highlight-current-row>
										<el-table-column width="55" label="序号">
											<template slot-scope="scope">
												<span>{{scope.$index+1}}</span>
											</template>
										</el-table-column>
										<el-table-column prop="Name" label="名称">
											<template slot-scope="scope">
												<el-col :span="23">
													<el-input v-model="scope.row.Name" size="mini" placeholder="名称" />
												</el-col>
											</template>
										</el-table-column>
										<el-table-column prop="addressType" label="地址类型">
											<template slot-scope="scope">
												<DictionarySelect v-model="scope.row.AddressType" dicTypeCode="PlatformAddressTypeCode"
											datType="Int"></DictionarySelect>
											</template>
										</el-table-column>
										<el-table-column prop="MemberProductCode" label="地址" width="400">
											<template slot-scope="scope">
												<el-col :span="23">
													<Distpicker :placeholders="scope.row"></Distpicker>
												</el-col>
											</template>
										</el-table-column>
										<el-table-column prop="AddressDetail" label="详细地址">
											<template slot-scope="scope">
												<el-col :span="23">
													<el-input v-model="scope.row.AddressDetail" size="mini" placeholder="详细地址" />
												</el-col>
											</template>
										</el-table-column>
										<el-table-column label="经纬度" width="260">
											<template slot-scope="scope">
												<el-col :span="10">
													<el-input v-model="scope.row.Longitude" size="mini" placeholder="经度" />
												</el-col>
												<el-col :span="10">
													<el-input v-model="scope.row.Latitude" size="mini" placeholder="纬度" />
												</el-col>
												<el-col :span="4">
													<el-button @click="locate(scope.row)">定位</el-button>
												</el-col>
											</template>
										</el-table-column>
										<el-table-column prop="ContactName" label="联系人">
											<template slot-scope="scope">
												<el-col :span="23">
													<el-input v-model="scope.row.ContactName" size="mini" placeholder="联系人" />
												</el-col>
											</template>
										</el-table-column>
										<el-table-column prop="ContactPhone" label="联系电话">
											<template slot-scope="scope">
												<el-col :span="23">
													<el-input v-model="scope.row.ContactPhone" size="mini" placeholder="联系人" />
												</el-col>
											</template>
										</el-table-column>
										<el-table-column fixed="right" label="操作" width="120">
											<template slot-scope="scope">
												<el-button size="small" type="text" @click="dataSource.AddressInfoList.splice(scope.$index, 1)">删除</el-button>
											</template>
										</el-table-column>
									</el-table>
								</div>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>

				<el-tabs class="done" type="border-card" v-model="activeName5">
					<el-tab-pane label="资质信息" name="tab1">
						<div class="form-padding h-handle-table h-table-list">
							<div class="h-handle-button  h-handle-bg">
								<el-button type="primary" size="small" @click="certAdd">新增</el-button>
							</div>
							<div class="table-s">
								<el-table :data="dataSource.CertList" border style="width: 100%">
									<el-table-column width="55" label="序号">
										<template slot-scope="scope">
											<span>{{scope.$index+1}}</span>
										</template>
									</el-table-column>
									<el-table-column prop="CertFileName" label="文件URL">
										<template slot-scope="scope">
											<span v-if="scope.row['IsEdit']">{{scope.row['CertFileName']}}</span>
											<el-input v-else v-model="scope.row['CertFileName']" suffix-icon="el-icon-upload2" readonly
												@click.native="uploadClick(scope.$index)"></el-input>
										</template>
									</el-table-column>
									<el-table-column prop="CertName" label="资质文件名称">
										<template slot-scope="scope">
											<span v-if="scope.row['IsEdit']">{{scope.row['CertName']}}</span>
											<el-input v-else v-model="scope.row['CertName']"></el-input>
										</template>
									</el-table-column>
									<el-table-column prop="CertNo" label="证书编号">
										<template slot-scope="scope">
											<span v-if="scope.row['IsEdit']">{{scope.row['CertNo']}}</span>
											<el-input v-else v-model="scope.row['CertNo']"></el-input>
										</template>
									</el-table-column>
									<el-table-column prop="EffectiveDate" label="有效期">
										<template slot-scope="scope">
											<span v-if="scope.row['IsEdit']">{{scope.row['EffectiveDate']}}</span>
											<el-date-picker v-else v-model="scope.row['EffectiveDate']" placeholder="选择日期" type="date"
												value-format="yyyy-MM-dd HH:mm:ss">
											</el-date-picker>
										</template>
									</el-table-column>
									<el-table-column prop="EffectiveDateFlag" label="是否效期控制">
										<template slot-scope="scope">
											<span v-if="scope.row['IsEdit']">{{scope.row['EffectiveDateFlag']}}</span>
											<DictionarySelect v-else v-model="scope.row['EffectiveDateFlag']" datType='Int'
												dicTypeCode="YesOrNoCode">
											</DictionarySelect>
											<!-- <el-select v-model="scope.row['EffectiveDateFlag']">
						
						<el-option label="是" value="1"></el-option>
						
						<el-option label="否" value="0"></el-option>
						
						</el-select> -->
										</template>
									</el-table-column>
									<el-table-column prop="EffectiveDateWarnDays" label="过期预警天数">
										<template slot-scope="scope">
											<span v-if="scope.row['IsEdit']">{{scope.row['EffectiveDateWarnDays']}}</span>
											<el-input v-else v-model="scope.row['EffectiveDateWarnDays']">
												<template slot="append">天</template>
											</el-input>
										</template>
									</el-table-column>
									<el-table-column label="操作">
										<template slot-scope="scope">
											<el-button type="text" size="small" @click="certDownload(scope.row)">下载
											</el-button>
											<el-button type="text" size="small" @click="dataSource.CertList.splice(scope.$index, 1)">删除
											</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
						

						<!-- <CertSelector ref="addCertRef" pageUrl="omsapi/memberinfo/searchmembercertlist"
							:relationId="dataSource.MemberId" v-else>
						</CertSelector> -->

					</el-tab-pane>
					<el-tab-pane label="合同信息" name="tab2">
						<ContractList ref="refcontractlist" :customerId="dataSource.MemberId" :isDisabled="false">
						</ContractList>
					</el-tab-pane>
				</el-tabs>
			</el-tab-pane>
			<el-tab-pane label="物流组织" name="tabLogOrg">
				<div class="form-padding h-handle-table h-table-list">
					<div class="h-handle-button  h-handle-bg">
						<el-button type="text" size="small" @click="orgControlShow(0)">新增</el-button>
					</div>
					<div class="table-s">
						<el-table ref="_orgList" :data="dataSource.MemberOrgList" style="width: 100%" border highlight-current-row>
							<el-table-column type="index" width="55" label="序号"></el-table-column>
							<el-table-column prop="OrgCode" label="组织机构编码"></el-table-column>
							<el-table-column prop="OrgName" label="组织机构名称"></el-table-column>
							<el-table-column label="操作">
								<template slot-scope="scope">
									<el-button @click="dataSource.MemberOrgList.splice(scope.$index, 1)">移除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="承运商评价" name="tabEvaluate">
				<div class="form-padding h-handle-table h-table-list">
					<div class="h-handle-button h-handle-bg">
						<el-button type="text" size="small" @click="onMemberAssessAdd">新增</el-button>
					</div>
					<div class="table-s">
						<el-table ref="_assessList" :data="dataSource.MemberAssessList" style="width: 100%" border highlight-current-row>
							<el-table-column type="index" width="55" label="序号"></el-table-column>
							<el-table-column prop="" label="评价时间">
								<template slot-scope="scope">
									<el-date-picker v-model.trim="scope.row.AssessDate" placeholder="选择日期"  type="datetime" value-format="yyyy-MM-dd HH:mm:ss">
                                    </el-date-picker>
								</template>
							</el-table-column>
							<el-table-column prop="" label="评价描述">
								<template slot-scope="scope">
									<el-input v-model="scope.row.AssessContent"></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="" label="评价分数">
								<template slot-scope="scope">
									<el-input v-model="scope.row.AssessScore"></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="" label="评价等级">
								<template slot-scope="scope">
									<DictionarySelect v-model="scope.row.AssessClass" datType='Int' dicTypeCode="MemberAssessClassCode">
									</DictionarySelect>
								</template>
							</el-table-column>
							<el-table-column prop="" label="评价人">
								<template slot-scope="scope">
									<el-input v-model="scope.row.AssessName"></el-input>
								</template>
							</el-table-column>
							<el-table-column label="操作">
								<template slot-scope="scope">
									<el-button @click="dataSource.MemberAssessList.splice(scope.$index, 1)">移除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="结算信息" name="tabSettle">
				<div class="form-padding h-handle-table h-table-list">
					<div class="h-handle-button  h-handle-bg">
						<el-button type="text" size="small" @click="onMemberSettleAdd">新增</el-button>
					</div>
					<div class="table-s">
						<el-table ref="_settleList" :data="dataSource.MemberSettleList" style="width: 100%" border highlight-current-row>
							<el-table-column type="index" width="55" label="序号"></el-table-column>
							<el-table-column prop="" label="结算对象">
								<template slot-scope="scope">
									<el-input v-model="scope.row.SettleObject"></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="" label="结算方式">
								<template slot-scope="scope">
									<DictionarySelect v-model="scope.row.SettleType" datType='Int' dicTypeCode="MemberSettleTypeCode">
									</DictionarySelect>
								</template>
							</el-table-column>
							<el-table-column prop="" label="收款账号">
								<template slot-scope="scope">
									<el-input v-model="scope.row.PayeeAccount"></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="" label="开户行名称">
								<template slot-scope="scope">
									<el-input v-model="scope.row.PayeeAccountBank"></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="" label="发票类型">
								<template slot-scope="scope">
									<DictionarySelect v-model="scope.row.InvoiceType" datType='Int' dicTypeCode="MemberInvoiceTypeCode">
									</DictionarySelect>
								</template>
							</el-table-column>
							<el-table-column prop="" label="税号">
								<template slot-scope="scope">
									<el-input v-model="scope.row.FaxNo"></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="" label="税率">
								<template slot-scope="scope">
									<!-- <el-input v-model="scope.row.FaxRate"></el-input> -->
									<DictionarySelect v-model="scope.row.FaxRate" datType='Int' dicTypeCode="MemberFaxRateCode">
									</DictionarySelect>
								</template>
							</el-table-column>
							<el-table-column prop="" label="国家地区" width="400">
								<template slot-scope="scope">
									<Distpicker :placeholders="scope.row"></Distpicker>
								</template>
							</el-table-column>
							<el-table-column prop="" label="备注">
								<template slot-scope="scope">
									<el-input v-model="scope.row.Remark"></el-input>
								</template>
							</el-table-column>
							<el-table-column label="操作">
								<template slot-scope="scope">
									<el-button @click="dataSource.MemberSettleList.splice(scope.$index, 1)">移除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>

		<DialogEx :options="orgConfig.viewOptions" title="组织机构" @onOk="onOrgControlOk">
			<OrgSelectControl ref="orgControl" :config="orgConfig.data" @onSelectedRow="onOrgControlSelectedRow">
			</OrgSelectControl>
		</DialogEx>
		<DialogEx :options="uploadConfig.viewConfig" :title="uploadConfig.viewConfig.title" @onOk="onConfirmOk" size="small">
            <el-form ref="upload">
                <el-form-item>
                    <el-col :span="24">
                        <el-upload :action="uploadUrl" drag ref="upload" :show-file-list="false" :auto-upload="true" accept=".jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.txt,.mp4" :on-success="uploadSuccess" :limit="10" :on-exceed="uploadExceed" :before-upload="uploadBefore" class="el-fileUpload">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">
                                注：支持.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.txt,.mp4文件，且不超过10MB</div>
                        </el-upload>
                    </el-col>
                </el-form-item>
            </el-form>
        </DialogEx>
	</div>
</template>

<script>
	import sha from '../../../../../scripts/sha.js';
	export default {
		data() {
			var checkMemberName = (rule, value, callback) => {
				if (!value) return callback(new Error('承运商名称不能为空'));
				callback();
			};
			//var checkContactPerson = function (rule, value, callback) {
			//    if (!this.isDisabled) {
			//        if (!value) return callback(new Error('联系人不能为空'));
			//    }
			//    callback();
			//};
			var checkContactMobile = (rule, value, callback) => {
				var phoneReg = /^1[3-578]\d{9}$/;
				if (!this.isDisabled) {
					//if (!value) return callback(new Error('联系电话不能为空'));
					if (value && !phoneReg.test(value)) return callback(new Error('联系电话格式不正确'));
				}
				callback();
			};
			var checkExpCompanyCode = (rule, value, callback) => {
				if (this.isExpress) {
					if (!value) return callback(new Error('快递公司编码不能为空'));
				};
				callback();
			};
			//var checkMemberAddress = function (rule, value, callback) {
			//    if (!value) return callback(new Error('地址不能为空'));
			//    callback();
			//};
			return {
				isExpress: false,
				ContactPersons: [],
				multipleSelection: [],
				tab2activeName: 'taborderitem',
				isDisabled: false,
				activeName: 'tabMemberInfo',
				activeName1: 'tabproductinfo',
				activeName2: "tabproductinfo",
				activeName5: "tab1",
				memberInfoCheckRule: {
					MemberName: [{
						validator: this.checkMemberName,
						trigger: 'blur'
					}],
					//ContactPerson: [{ validator: checkContactPerson, trigger: 'blur' }],
					ContactMobile: [{
						validator: checkContactMobile,
						trigger: 'blur'
					}],
					ExpCompanyCode: [{
						validator: checkExpCompanyCode,
						trigger: 'blur'
					}],
					BelongType:[{
							required: true,
							message: '请选择承运商类别',
							trigger: 'change'
						}]
					//MemberAddress: [{ validator: checkMemberAddress, trigger: 'blur' }]
				},
				CancalProductInfo: [],
				expressOptions: [],
				options: [{
						label: '城配',
						value: '1'
					},
					{
						label: '快递',
						value: '2'
					},
					{
						label: '快运',
						value: '3'
					},
					{
						label: '自提',
						value: '4'
					}
				],
				orgConfig:{
                    viewOptions: {
                        visible: false,
                        size: 'small',
                        customClass: 'controlDialog'
                    },
                    data:{
						multiple:true,
                    },
                    selectedRow:{}
                },
				uploadUrl: window.AppConfig.apiCustomerUrl + "omsapi/files/upload?currentType=" + this.$store.state.global
                .currentType + "&currentCCode=" + this.$store.state.global.currentCCode + "&token=" + this.Utils
                .getToken(),
				uploadConfig: {
					fileType: 800,
					isShowImage: false, //查看图片
					showImage: '',
					currentIndex: null,
					currentFile: {},
					viewConfig: {
						visible: false,
						size: 'small',
						title: '文件上传',
						customClass: 'vendorDialog'
					}
				}
			}
		},
		props: {
			config: {
				isDetailDisplay: false
			},
			dataSource: {
				MemberProductList: []
			}
		},
		mounted() {
			this.Event.$on("clearEditMemberInfoForm", () => this.resetForm);
			this.GetOptionList();
		},
		watch: {
			dataSource: {
				handler(curVal, oldVal) {
					this.isDisabled = this.dataSource.MemberId != null;
					this.isExpress = this.dataSource.MemberType == 2;
					this.$refs.refcontractlist.getContractList(this.dataSource.MemberId);
				},
				deep: true
			}
		},
		created: function() {
			this.Event.$on("clearForm", this.resetForm);
		},
		methods: {
			syncOrgEvent(row){
				if(row){
					this.dataSource.OrganizationId = row.Id;
					this.dataSource.OrganizationName = row.Name;
				}
			},
			syncSiteEvent(row){
				if(row){
					this.dataSource.SiteId = row.Id;
					this.dataSource.SiteName = row.Name;
				}
			},
			checkMemberName(rule, value, callback) {
				if (!value) return callback(new Error('承运商名称不能为空'));
				else {
					this.$ajax.send("omsapi/memberinfo/checkshipprovidernameexist", "post", this.dataSource, (data) => {
						if (data.OperationDesc == "名称已存在") {
							return callback(new Error('承运商名称已存在'));
						}
					});
				}
				callback();
			},
			addProduct: function() { //添加产品
				var _this = this;
				var newRow = {
					MemberId: _this.dataSource.MemberId,
					MemberProductCode: '',
					MemberProductName: '',
					MemberProductID: 0,
					IsDisabled: false
				};
				_this.dataSource.MemberProductList.push(newRow);
			},
			editProduct: function(row, index, ) { //编辑产品
				var _this = this;
				row.IsDisabled = false;
				if (_this.dataSource.MemberProductID !== null && row.MemberId != null) {
					_this.CancalProductInfo.EnterpriseCode = row.EnterpriseCode;
					_this.CancalProductInfo.MemberProductCode = row.MemberProductCode;
					_this.CancalProductInfo.MemberProductName = row.MemberProductName;
					_this.CancalProductInfo.MemberProductID = row.MemberProductID;
					_this.CancalProductInfo.EnterpriseName = row.MemberId;
				}
			},
			saveContact: function(row, index) { //保存产品
				var _this = this;
				if (row.MemberProductCode == '' || row.MemberProductName == '') {
					_this.Utils.messageBox("产品编码或者产品名称不能为空.", "warning");
					return false;
				}

				if (_this.dataSource.MemberId !== null) {
					_this.$ajax.send("omsapi/memberinfo/saveproduct", "post", row, (data) => {
						if (data.IsSuccess) {
							row.MemberProductID === null && (row.MemberProductID = data.OperationDesc);
							_this.Utils.messageBox("保存产品成功.", "success");
							row.IsDisabled = true;
						} else {
							_this.Utils.messageBox("保存产品失败，清重试.", "warning");
						}
					});
				} else {
					row.IsDisabled = true;
				}
			},
			removeProduct: function(row, index) { //移除产品
				var _this = this;
				if (_this.dataSource.MemberId !== null && row.MemberProductID != null) {
					_this.$ajax.send("omsapi/memberinfo/deleteproduct", "post", row, (data) => {
						if (data.IsSuccess) {
							_this.Utils.messageBox("移除产品成功.", "success");
							this.dataSource.MemberProductList.remove(row);
						} else {
							_this.Utils.messageBox("移除产品失败，清重试.", "warning");
						}
					});
				} else {
					this.dataSource.MemberProductList.remove(row);
				}
			},
			cancelProduct: function(row, index) { //取消添加产品
				var _this = this;
				if (_this.dataSource.MemberId !== null && row.MemberProductID != null) {
					row.EnterpriseCode = _this.CancalProductInfo.EnterpriseCode;
					row.MemberProductCode = _this.CancalProductInfo.MemberProductCode;
					row.MemberProductName = _this.CancalProductInfo.MemberProductName;
					row.MemberProductID = _this.CancalProductInfo.MemberProductID;
					row.EnterpriseName = _this.CancalProductInfo.MemberId;
				}
				row.IsDisabled = true;
			},
			tab1Click: function(tab, event) {},
			back() {
				this.$parent.checkSaveStatus();
			},
			addMemberInfo() {
				this.Event.$emit("onAddMemberInfo");
			},
			resetForm: function(memberId) {
				!this.isPassValidate && this.$refs['_memberInfoForm'].resetFields(); //清空表单
			},
			saveMemberInfo() {
				var _this = this;
				//if (!_this.dataSource.CityName || !_this.dataSource.CountyName || !_this.dataSource.ProvinceName) {
				//    _this.Utils.messageBox("地区信息不全.", "error");
				//    return false;
				//}
				// if(_this.isExpress){
				//     if (this.dataSource.ExpressList == undefined || this.dataSource.ExpressList == null || this.dataSource.ExpressList.length < 1 ) {
				//         this.Utils.messageBox("租户数据字典配置不完整..", "error");
				//         return;
				//     }
				// }                

				var list = _this.dataSource.MemberProductList;
				if (list != null && list != []) {
					for (var i in list) {
						if (list[i].MemberProductCode == "" || list[i].MemberProductName == "") {
							_this.Utils.messageBox("承运商产品信息不全", "error");
							return false;
						}
					}
				}

				_this.$refs["_memberInfoForm"].validate((valid) => {
					_this.isPassValidate = valid;
					if (valid) {
						var routeName = _this.dataSource.MemberId === null ? "createshipprovider" :
							"updateshipprovider";
						this.$ajax.send("omsapi/memberinfo/" + routeName, "post", _this.dataSource, (data) => {
							_this.$parent.syncDataSource();
							_this.Event.$emit("reloadMemberInfoPageList", data.Result);
							_this.Utils.messageBox("保存成功.", "success");
						});
					} else {
						return false;
					}
				});
			},
			onMemberTypeChange(type) {
				this.isExpress = false;
				if (type == 2) {
					this.isExpress = true;
				}
			},
			checkMemberProduct: function(sign, index, val) {
				var _this = this;
				var list = this.dataSource.MemberProductList;
				if (list != [] || list != null || list != undefined) {
					for (var item in list) {
						if (sign == 0) {
							if (list[item].MemberProductCode == val && item != index + "") {
								_this.Utils.messageBox("已经存在产品编码", "error");
								list[index].MemberProductCode = "";
								return false;
							}
						} else {
							if (list[item].MemberProductName == val && item != index + "") {
								_this.Utils.messageBox("已经存在产品名称", "error");
								list[index].MemberProductName = "";
								return false;
							}
						}
					}
				}

			},
			GetOptionList: function() {
				var _this = this;
				_this.$ajax.send("omsapi/memberinfo/getsysdiclistbycode", "get", {
					id: 702
				}, (data) => {
					if (data.IsSuccess) {
						_this.expressOptions = data.Result;
					} else {
						_this.Utils.messageBox("获取快递公司类型列表失败", "warning");
					}
				});
			},
			addContactInfo: function() {
				if (!this.dataSource.LinkManDTOList) {
					this.dataSource.LinkManDTOList = [];
				}
				var data = {
					ContactName: null,
					ContactPhone: null,
					Email: null
				};
				this.dataSource.LinkManDTOList.push(data);
			},
			addAddressInfo: function() {
				if (!this.dataSource.AddressInfoList) {
						this.dataSource.AddressInfoList = [];
					}
					var data = {
						AddressType: null,
						ProvinceName: null,
						CityName: null,
						CountyName: null,
						ProvinceID: null,
						CityID: null,
						CountyID: null,
						AddressDetail: null,
						Longitude: null,
						Latitude: null,
						ContactName: null,
						ContactPhone: null,
					};
					this.dataSource.AddressInfoList.push(data);
			},
			certAdd() {
				if (!this.dataSource.CertList) {
						this.dataSource.CertList = [];
					}
					var data = {
						RelationId: this.dataSource.MemberId,
						PlatformUserType: 0, //承运商类型
						IsAdd: true,
						CertFileName: '',
						CertFileUrl: '',
						CertName: '',
						CertNo: '',
						EffectiveDate: null,
						EffectiveDateFlag: 100,
						EffectiveDateWarDays: null
					};
					this.dataSource.CertList.push(data);

			},
			uploadClick(index) {
				this.uploadConfig.viewConfig.visible = true;
				this.uploadConfig.currentIndex = index;
			},
			certDownload(row) {
				// var url = row.AttachmentUrl;
				// if (url) {
				// 	this.Utils.exportExt(url);
				// }
				var _this = this;
				var urlFile = "omsapi/files/download?file=" + row.CertFileName + "&url=" + row.CertFileUrl + "&token=" +
					sha.getToken();
				_this.Utils.exportUrl(urlFile);
			},
			orgControlShow(){
				this.orgConfig.viewOptions.visible=true;
			},
			onOrgControlSelectedRow(rows){
				debugger;
                if (rows && rows.length>0) {
                    this.orgConfig.selectedRow = rows;
				}
            },
            onOrgControlOk(){
				debugger;
				var data = this.$refs.orgControl.getSelectedAll();
				this.dataSource.MemberOrgList = this.Utils.arrayUnion([this.dataSource.MemberOrgList,data.OrgList],'OrgId');
            },
			onMemberAssessAdd(){
				var item = {};
				this.dataSource.MemberAssessList.push(item);
			},
			onMemberSettleAdd(){
				var item = {};
				this.dataSource.MemberSettleList.push(item);
			},
			locate(row){
				this.Utils.setLocation(row);
			},
			uploadSuccess(response, file, fileList) {
				if (response.IsSuccess) {
					if (response.Result) {
						this.uploadConfig.currentFile = response.Result;
					} else {
						this.$message.error(response.OperationDesc);
					}
				}
			},
			uploadBefore(file) {
				this.uploadConfig.currentFile = null;
				const picType = (file.type === 'image/jpeg') || (file.type === 'image/png') || (file.type ===
						'video/mp4') ||
					(file.type === 'application/msword') || (file.type === 'application/vnd.ms-excel') ||
					(file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
					(file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
					(file.type === 'text/plain') || (file.name.endsWith('.xls')) || (file.name.endsWith('.xlsx'));
				const isLt50M = file.size / 1024 / 1024 < 10;
				console.log("校验类型");
				if (!picType) {
					this.$message.error('上传文件只能是.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.txt,.mp4格式!');
				}
				console.log("校验大小");
				if (!isLt50M) {
					this.$message.error('上传文件大小不能超过 10MB!');

				}
				return picType && isLt50M;
			},
			uploadExceed(files, fileList) {
				this.$message.warning(
					`当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
			},
			uploadShowImage(row) {
				var url = row.AttachmentUrl;
				if (url) {
					this.uploadConfig.showImage = url;
					this.uploadConfig.isShowImage = !this.isShowImage;
				}
			},
			isImage(fileName) {
				//获取最后一个.的位置
				var index = fileName.lastIndexOf(".");
				//获取后缀
				var ext = fileName.substr(index + 1);
				return ['png', 'jpg', 'jpeg'].indexOf(ext.toLowerCase()) !== -1;
			},
			onConfirmOk() {
				debugger;
				var currentFile = this.uploadConfig.currentFile;
				if (currentFile != null) {
					this.dataSource.CertList[this.uploadConfig.currentIndex].CertFileUrl = currentFile.Url;
					this.dataSource.CertList[this.uploadConfig.currentIndex].CertFileName = currentFile.FileName;
				}
			},
		},
		components: {
			"ContactInfoSelector": resolve => {
				require(['./contactselector.vue'], resolve)
			},
			"AddressInfoSelector": resolve => {
				require(['./addressselector.vue'], resolve)
			},
			"CertSelector": resolve => {
				require(['./certselector.vue'], resolve)
			},
			"ContractList": resolve => {
				require(['@/components/enterprise/selector/customercontractlist.vue'], resolve)
			},
			"OrgSelectControl": resolve => {
				require(['@/components/enterprise/components/logorgselector.vue'], resolve)
			},
		}
	}
</script>
<style>
	.el-fileUpload .el-upload {}

	.vendorDialog.el-dialog {
		width: 30%;
	}

	.vendorDialog.el-dialog .el-upload {
		display: block;
	}

	.vendorDialog.el-dialog .el-upload-dragger {
		width: 100%;
	}
</style>